import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import * as React from "react";
import styled from "styled-components";

import {
  BulletTick,
  Button,
  Column,
  CurvedSectionDivider,
  CurvedSectionDividerGlow,
  FormattedText,
  Grid,
  Gutter,
  HelmetSeo,
  HorizontalAlign,
  Layout,
  Line,
  MaxWidth,
  PageMaxWidth,
  Section,
  Text,
  Visibility,
} from "../components";
import { DocRouterIllustration } from "../components/docrouter";
import { Color, Dimen } from "../constants";

type Props = {
  data: GatsbyTypes.DocRouterPageQuery;
};

export const query = graphql`
  query DocRouterPage {
    prismicDocrouterPage {
      data {
        hero_title
        hero_button_label
        hero_main_svg {
          url
        }

        transform_heading
        transform_points {
          transform_point_svg {
            url
          }
          transform_point_text
        }

        benefits_heading
        benefits {
          benefit_svg {
            url
          }
          benefit_text
        }

        prompt_text
        prompt_button_label

        features_heading
        features {
          feature_text
        }

        form_heading

        seo_title
        seo_description
        share_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
      }
    }
  }
`;

export default function DocRouterPage({ data }: Props) {
  const page = data.prismicDocrouterPage?.data;
  if (!page) throw new Error("page missing");

  const scrollToForm = (ev: React.SyntheticEvent) => {
    document
      .getElementById("form")
      ?.scrollIntoView({ behavior: "smooth", block: "start" });
    ev.preventDefault();
  };

  return (
    <Layout footerBackColor={Color.newDark2}>
      <HelmetSeo {...page} />

      <Section>
        <PageMaxWidth>
          <Gutter size={3} />
          <Grid alignColumns="center" reverseColumns>
            <Column size={6}>
              <MaxWidth width={528}>
                <img src={page.hero_main_svg?.url} alt="" />
              </MaxWidth>
            </Column>
            <Column size={6}>
              <Text typeface="h2" as="h1" bold>
                {page.hero_title}
              </Text>
              <Gutter size={2} />
              <HorizontalAlign align="flex-start">
                <Button
                  colorTheme="orange"
                  label={page.hero_button_label}
                  onClick={scrollToForm}
                  href="#form"
                />
              </HorizontalAlign>
            </Column>
          </Grid>
          <Gutter size={3} />
        </PageMaxWidth>
      </Section>

      <CurvedSectionDivider
        position="top"
        frontColor="newDark2"
        backColor="transparent"
      />
      <Section background="dark" tint="light">
        <PageMaxWidth>
          <Gutter />
          <MaxWidth width={700}>
            <HorizontalAlign align="center">
              <Text color="light1" bold typeface="h3" as="h2" align="center">
                {page.transform_heading}
              </Text>
            </HorizontalAlign>
            <Gutter />
          </MaxWidth>
          <Gutter size={1.5} />
          <Grid justifyColumns="center">
            {page.transform_points?.map((point, i) => (
              <Column size={(12 / (page.transform_points?.length ?? 3)) as any}>
                <StyledTransformPoint
                  $last={i === (page.transform_points?.length || 0) - 1}
                >
                  <Text
                    typeface="h5"
                    bold
                    as="p"
                    color={
                      ["yellow1", "lightBlue1", "green2"][
                        i % 3
                      ] as keyof typeof Color
                    }
                  >
                    {point?.transform_point_text}
                  </Text>
                  <Gutter md={0.5} />
                  <img src={point?.transform_point_svg?.url} alt="" />
                </StyledTransformPoint>
              </Column>
            ))}
          </Grid>
          <Gutter />
        </PageMaxWidth>
      </Section>
      <CurvedSectionDivider
        position="bottom"
        frontColor="newDark2"
        backColor="transparent"
      />

      {page.benefits && (
        <Section>
          <PageMaxWidth>
            <Gutter size={4} />
            <Text as="h2" typeface="h3" bold align="center">
              {page.benefits_heading}
            </Text>
            <Gutter size={2.5} />

            <Grid>
              {page.benefits.map((benefit) => (
                <Column size={4}>
                  <StyledBenefit>
                    <Text>{benefit?.benefit_text}</Text>
                    <div className="area-bottom">
                      <img src={benefit?.benefit_svg?.url} alt="" />
                    </div>
                  </StyledBenefit>
                  <Gutter />
                </Column>
              ))}
            </Grid>
          </PageMaxWidth>
        </Section>
      )}

      <Section>
        <PageMaxWidth>
          <Gutter size={4} md={2} />
          <Grid alignColumns="center">
            <Column size={2} />
            <Column size={5}>
              <Visibility hideAbove="md">
                <Text as="p" typeface="h3" align="center">
                  {page.prompt_text}
                </Text>
              </Visibility>
              <Visibility hideBelow="md">
                <Text typeface="h3">{page.prompt_text}</Text>
              </Visibility>
            </Column>
            <Column size={3}>
              <HorizontalAlign md="center" align="flex-end">
                <Button
                  colorTheme="orange"
                  label={page.prompt_button_label}
                  onClick={scrollToForm}
                  href="#form"
                />
              </HorizontalAlign>
            </Column>
            <Column size={2} />
          </Grid>
          <Gutter size={4} md={2} />
        </PageMaxWidth>
      </Section>

      <CurvedSectionDividerGlow
        position="top"
        glowColor="orange"
        backColor="light1"
      />
      <Section background="dark" tint="light">
        <PageMaxWidth>
          {page.features && (
            <Grid>
              <Column size={3}>
                <HorizontalAlign align="flex-start" md="center">
                  <Gutter />
                  <Text typeface="h3" color="light1" bold as="h3">
                    {page.features_heading}
                  </Text>
                </HorizontalAlign>
              </Column>
              <Column size={5}>
                <Gutter />
                {page.features
                  .slice(0, Math.ceil(page.features.length / 2))
                  .map(
                    (f) =>
                      f && (
                        <BulletTick key={f.feature_text}>
                          <Text color="light1">{f.feature_text}</Text>
                        </BulletTick>
                      )
                  )}
              </Column>
              <Column size={4}>
                <Gutter />
                {page.features.slice(Math.ceil(page.features.length / 2)).map(
                  (f) =>
                    f && (
                      <BulletTick key={f.feature_text}>
                        <Text color="light1">{f.feature_text}</Text>
                      </BulletTick>
                    )
                )}
              </Column>
            </Grid>
          )}
          <div id="form" />
          <Gutter size={4} />
          <StyledFormWrap>
            <MaxWidth width={560}>
              <Text as="h2" bold align="center" color="dark4">
                {page.form_heading}
              </Text>
              <iframe
                src="https://forms.wisetechglobal.com/shipamax/en-us/contact"
                width="100%"
                height="850"
                frameBorder="0"
                allowTransparency={true}
                style={{ border: 0, margin: 0 }}
              />
            </MaxWidth>
          </StyledFormWrap>
          <Gutter size={2} />
        </PageMaxWidth>
      </Section>
    </Layout>
  );
}

const StyledTransformPoint = styled("div")<{ $last: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${(p) =>
    !p.$last &&
    `
    &::after {
      content: '';
      width: 1px;
      height: 130px;
      background-color: #7c7c7c;
      position: absolute;
      top: 64px;
      right: -${Dimen.gutterWidth / 2}px; 
    }
  `}

  img {
    width: 158px;
  }

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    flex-direction: row-reverse;
    max-width: 202px;
    margin: 0 auto;
    padding-bottom: ${Dimen.gutterWidth}px;

    p {
      flex: 1;
    }

    img {
      width: 84px;
    }

    &::after {
      top: auto;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      height: 1px;
    }
  }
`;

const StyledBenefit = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 134px;
  background-color: ${Color.light1};
  border-radius: 8px;
  box-shadow: 0px 8px 30px rgba(82, 87, 102, 0.15);
  padding: 17px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .area-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    > img {
      width: 20px;
    }
  }
`;

const StyledFormWrap = styled.div`
  background-color: ${Color.light1};
  border-radius: 8px;
  padding: 30px 20px 10px;
  margin: 0 auto;
  max-width: 720px;
`;
